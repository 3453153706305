import Vue from "vue";
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";
import { ExternalService, AssetService } from "@services";
import { Geolocation } from "@capacitor/geolocation";
import idb from "@/api/idb";

export default {
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("location", ["geoLocationCoords"]),
  },
  methods: {
    ...mapActions("location", ["setGeoLocation"]),
    responseMessage(messageKey = "", dismissAfter = 0, type = "success") {
      this.$snackbar.showMessage({
        content: this.$t(messageKey),
        color: type,
        timeout: dismissAfter,
      });
    },
    getErrorMessage(errors) {
      let errStr = "";
      if (errors.length > 1) {
        errors.forEach((err, index) => {
          if (err.code) {
            errStr += this.$te(err?.code) ? this.$t(err?.code) : err?.englishTranslation;
            errStr += index + 1 < errors?.length ? ", " : " ";
          } else {
            errStr = err.message;
          }
        });
      } else {
        errStr = errors.message;
      }

      return errStr;
    },
    async getLocation() {
      if (Vue.prototype.$online) {
        await Geolocation.getCurrentPosition().then(
          (geo) => {
            this.setGeoLocation(geo.coords.latitude + ", " + geo.coords.longitude + ", " + geo.coords.accuracy);
          },
          (err) => {
            if (err.message.indexOf("denied" > 0)) {
              ExternalService.getGeoLocationByIP().then((response) => {
                this.setGeoLocation(response.data.latitude + ", " + response.data.longitude);
              });
            } else {
              ExternalService.getGeoLocationByIP().then((response) => {
                this.setGeoLocation(response.data.latitude + ", " + response.data.longitude);
              });
            }
          },
        );
      }
    },
    geoISO6709format(geo) {
      let lat, lng, acc;
      if (!geo) {
        return;
      }
      geo = geo.split(", ");
      lat = geo[0];
      lng = geo[1];
      acc = geo[2] ? geo[2] : "";
      lat = lat.indexOf("-") == -1 ? "+" + lat : lat;
      lng = lng.indexOf("-") == -1 ? "+" + lng : lng;
      let latlng = lat + lng + "/" + acc;
      return latlng;
    },
    getDeviceInfo() {
      let method = null;
      const os = this.$deviceInfo?.operatingSystem; //'ios' | 'android' | 'windows' | 'mac' | 'unknown'
      const p = this.$deviceInfo?.platform; //'ios' | 'android' | 'web'

      if (p == "ios") {
        method = "ios_app";
      } else if (p == "android") {
        method = "android_app";
      } else if (p == "web") {
        if (os == "ios" || os == "android") {
          method = "mobile_web";
        } else if (os == "windows" || os == "mac") {
          method = "desktop_web";
        } else {
          method = "web";
        }
      } else {
        method = "unknown";
      }
      return method;
    },
    formatDuration(start, end, addSeconds = false) {
      if (start && end) {
        let dateEnd = DateTime.fromISO(end, { zone: "utc" }).setZone(this.settings?.timeZone || "local");
        let dateStart = DateTime.fromISO(start, { zone: "utc" }).setZone(this.settings?.timeZone || "local");
        let diffCheck = dateEnd.diff(dateStart).toMillis();
        if (diffCheck < 0) {
          dateEnd = dateEnd.plus({ days: 1 });
        }
        let diff;
        if (addSeconds) {
          diff = dateEnd.diff(dateStart).toFormat("hh:mm:ss");
        } else {
          diff = dateEnd.plus({ seconds: 59 }).diff(dateStart).toFormat("hh:mm");
        }
        return diff;
      }
      return "0:00";
    },
    formatTime(value, withSeconds = false) {
      if (!value) {
        return;
      }
      const dt = DateTime.fromISO(value, { zone: "utc" }).setZone(this.settings?.timeZone || "local");
      if (this.settings?.timeFormat24Hour) {
        return withSeconds
          ? dt.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
          : dt.toLocaleString(DateTime.TIME_24_SIMPLE);
      } else {
        return withSeconds ? dt.toLocaleString(DateTime.TIME_WITH_SECONDS) : dt.toLocaleString(DateTime.TIME_SIMPLE);
      }
    },
    getMonthDay(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toFormat("dd");
    },
    formatDateMonthYear(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toFormat("MM-yyyy");
    },
    formatDateShort(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toFormat("LLL d");
    },
    formatDateMonthDay(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toFormat("MMMM d");
    },
    formatDate(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },
    formatDateFull(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toLocaleString(DateTime.DATE_FULL);
    },
    formatDateAbbrMonthDayYear(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toFormat("LLL dd, yyyy");
    },
    formatDateTime(value) {
      if (!value) {
        return;
      }
      const dt = DateTime.fromISO(value, { zone: "utc" }).setZone(this.settings?.timeZone || "local");
      if (this.settings?.timeFormat24Hour) {
        return dt.setLocale(this.settings.lang).toFormat("ccc, LLL dd, yyyy HH:mm:ss");
      } else {
        return dt.setLocale(this.settings.lang).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
      }
    },
    formatDateTimeContextual(value) {
      if (!value) {
        return;
      }
      const dt = DateTime.fromISO(value, { zone: "utc" }).setZone(this.settings?.timeZone || "local");
      const now = DateTime.local().setZone(this.settings?.timeZone || "local");

      if (dt > now.startOf("day")) {
        if (this.settings.timeFormat24Hour) {
          return dt.setLocale(this.settings.lang).toFormat("T");
        } else {
          return dt.setLocale(this.settings.lang).toFormat("t");
        }
      } else if (dt > now.minus({ weeks: 1 }).startOf("week")) {
        if (this.settings.timeFormat24Hour) {
          return dt.setLocale(this.settings.lang).toFormat("ccc T");
        } else {
          return dt.setLocale(this.settings.lang).toFormat("ccc t");
        }
      } else if (dt > now.minus({ days: 30 }).startOf("day")) {
        return dt.setLocale(this.settings.lang).toFormat("ccc M/d");
      } else {
        return dt.setLocale(this.settings.lang).toLocaleString();
      }
    },
    formatTime24(value) {
      if (!value) {
        return;
      }
      return DateTime.fromISO(value, { zone: "utc" })
        .setZone(this.settings?.timeZone || "local")
        .setLocale(this.settings.lang)
        .toFormat("HH:mm:ss");
    },
    clockedInDuration() {
      if (this.timeNow && this.timeClock.ClockStart) {
        const date1 = this.timeNow;
        const date2 = DateTime.fromISO(this.timeClock.ClockStart, { zone: "utc" }).setZone(
          this.settings?.timeZone || "local",
        );
        return date1.diff(date2).setLocale(this.settings.lang).toFormat("hh:mm:ss");
      }
    },
    convertNumToTime(number) {
      var hour = Math.floor(number);
      var decpart = number - hour;
      var min = 1 / 60;
      decpart = min * Math.round(decpart / min);
      var minute = Math.floor(decpart * 60) + "";
      if (minute.length < 2) {
        minute = "0" + minute;
      }
      hour = hour.toString();
      if (hour.length < 2) {
        hour = "0" + hour;
      }
      let time = hour + ":" + minute;
      return time;
    },
    parseTime(timeString) {
      //string to time parser, ie '1:00 pm','1:00 p.m.','1:00 p','1:00pm','1:00p.m.','1:00p','1 pm', '123', '2459', '2359', '2359am', '1100', '123p'
      if (timeString == "") return null;
      var time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
      if (time == null) return null;
      var hours = parseInt(time[1], 10);
      if (hours == 12 && !time[4]) {
        hours = 0;
      } else {
        hours += hours < 12 && time[4] ? 12 : 0;
      }
      var d = new Date();
      d.setHours(hours);
      d.setMinutes(parseInt(time[3], 10) || 0);
      d.setSeconds(0, 0);
      return d;
    },
    isDateExpired(checkDate) {
      return Date.parse(checkDate) < Date.now();
    },
    getFormTag(tags) {
      return tags ? tags.split(",", 1)[0] : "";
    },
    filterKeyDigits(e) {
      if (
        /[0-9]/.test(e.key) ||
        e.ctrlKey ||
        e.metaKey ||
        e.key === "Backspace" ||
        e.key === "Delete" ||
        e.key === "Home" ||
        e.key === "End" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight"
      ) {
        return;
      }
      e.preventDefault();
    },
    formatDateList(elements, datePropName, showWeekHeader = true) {
      let items = [];
      let i = 0;

      elements.forEach((item) => {
        if (item[datePropName]) {
          let dateStart = DateTime.fromISO(item[datePropName]);
          if (showWeekHeader) {
            let weekStart, weekEnd;
            let weekStartDate = dateStart.startOf("week");
            if (weekStartDate.hasSame(DateTime.local().startOf("week"), "week")) {
              weekStart = this.$t("t_this_week");
            } else {
              weekStart = weekStartDate.setLocale(this.settings.lang).toLocaleString({
                month: "short",
                day: "numeric",
              });
              if (weekStartDate.year !== DateTime.now().year) {
                weekEnd = weekStartDate.plus({ days: 6 }).setLocale(this.settings.lang).toLocaleString({
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                });
              } else {
                weekEnd = weekStartDate.plus({ days: 6 }).setLocale(this.settings.lang).toLocaleString({
                  month: "short",
                  day: "numeric",
                });
              }
            }
            if (!items.some((item) => item.weekStart === weekStart)) {
              item.weekStart = weekStart;
              item.weekEnd = weekEnd;
            } else {
              item.weekStart = "";
              item.weekEnd = "";
            }
          }

          let day;
          if (!dateStart) {
            day = "";
          } else if (dateStart.hasSame(DateTime.local(), "day")) {
            day = this.$t("t_today");
          } else if (dateStart.hasSame(DateTime.local().minus({ days: 1 }), "day")) {
            day = this.$t("t_yesterday");
          } else {
            day = dateStart.setLocale(this.settings.lang).toFormat("ccc, LLL d");
          }
          if (!items.some((item) => item.day === day)) {
            item.day = day;
          } else {
            item.day = "";
          }
        }
        items.push(item);
        i = i + 1;
      });
      return items;
    },
    sum(a) {
      return (a.length && parseFloat(a[0]) + this.sum(a.slice(1))) || 0;
    },
    showLoadBar() {
      this.$store.dispatch("loader/pending", null, { root: true });
      setTimeout(
        () => {
          this.$store.dispatch("loader/done", null, { root: true });
        },
        Vue.prototype.$online ? 10000 : 1000,
      );
    },
    hideLoadBar() {
      this.$store.dispatch("loader/done", null, { root: true });
    },
    async getImageAsset(assetId, assetPublicId, cacheable = true) {
      if (cacheable) {
        const s3Item = await idb.getS3Item(assetPublicId);
        if (s3Item) {
          return URL.createObjectURL(s3Item.value);
        }
      }
      const a = await AssetService.downloadAsset({
        assetId: assetId,
        token: assetPublicId,
      });
      if (a.data.mimeType.includes("image")) {
        return fetch(a.data.downloadUrl)
          .then((response) => response.blob())
          .then((blob) => {
            if (cacheable) {
              idb.putS3Item(assetPublicId, blob);
            }
            return URL.createObjectURL(blob);
          });
      }
    },
    async getAssetFromDownloadUrl(downloadUrl, publicId, cacheable = true) {
      if (cacheable) {
        const s3Item = await idb.getS3Item(publicId);
        if (s3Item) {
          return URL.createObjectURL(s3Item.value);
        }
      }
      return fetch(downloadUrl)
        .then((response) => response.blob())
        .then((blob) => {
          if (cacheable) {
            idb.putS3Item(publicId, blob);
          }
          return URL.createObjectURL(blob);
        });
    },
    /**
     * Processes an image from a base64 string, resizing it to the specified dimensions and quality.
     *
     * @param {string} base64String - The base64 encoded string of the image to be processed.
     * @param {Object} options - Optional parameters for processing the image.
     * @param {number} [options.maxDimension=1024] - The maximum dimension (width or height) for the processed image.
     * @param {number} [options.quality=0.9] - The quality of the processed image (0 to 1).
     * @returns {Promise<string>} A promise that resolves to the processed base64 string of the image.
     * @example
     * const processedImage = await this.processImage(originalBase64String, { maxDimension: 800, quality: 0.85 });
     */
    async processImage(base64String, { maxDimension = 600, quality = 0.7 } = {}) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calculate dimensions maintaining aspect ratio
          let width = img.width;
          let height = img.height;

          // Use provided maxDimension (default 1024px)
          const targetMaxWidth = maxDimension;

          // Scale to max width/height while maintaining aspect ratio
          if (width > height) {
            if (width > targetMaxWidth) {
              height = Math.round((height * targetMaxWidth) / width);
              width = targetMaxWidth;
            }
          } else {
            if (height > targetMaxWidth) {
              width = Math.round((width * targetMaxWidth) / height);
              height = targetMaxWidth;
            }
          }

          // Set canvas dimensions and draw
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to base64 with appropriate quality
          const processedBase64 = canvas.toDataURL("image/jpeg", quality).split(",")[1];
          resolve(processedBase64);
        };
        img.src = `data:image/jpeg;base64,${base64String}`;
      });
    },
    statusColor(status) {
      switch (status) {
        case 0:
          return "orange darken-1";
        case 1:
          return "blue";
        case 2:
          return "green darken-3";
        case 3:
          return "grey darken-1";
        case 4:
          return "grey darken-1";
        case 5:
          return "red";
        default:
          return "grey";
      }
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return "Pending";
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        case 3:
          return "Cancelled";
        case 4:
          return "Deleted";
        case 5:
          return "Overdue";
        default:
          return "";
      }
    },
  },
};
