import Vue from "vue";
import idb from "@/api/idb";
import { DateTime } from "luxon";
import { TimeClockService } from "@services";
import Helpers from "@/mixins/helpers";

const getDefaultTimeClock = () => {
  return {
    id: null,
    jobId: null,
    jobName: "",
    jobDescription: "",
    costCodeId: null,
    costCodeCode: "",
    timeCategoryId: null,
    timeCategoryName: "",
    status: "",
    friendlyStatus: "",
    clockStart: null,
    clockStartReasonId: null,
    clockStartReasonName: "",
    clockStartLatLng: "",
    clockStartLatLngMethod: "",
    clockEnd: null,
    clockEndReasonId: null,
    clockEndReasonName: "",
    clockEndLatLng: "",
    clockEndLatLngMethod: "",
    latestFitForDuty: true,
    latestIncident: true,
    createdOn: null,
    createdById: null,
    createdByFriendlyName: "",
    createdByImageUrl: "",
    createdByInitials: "",
    updatedOn: null,
    updatedById: null,
    updatedByFriendlyName: "",
    updatedByImageUrl: "",
    updatedByInitials: "",
    reviewedOn: null,
    reviewedById: null,
    reviewedByFriendlyName: "",
    reviewedByImageUrl: "",
    reviewedByInitials: "",
    syncedOn: null,
    clockStartNote: "", //not included on api endpoint props
    clockEndNote: "", //not included on api endpoint props
    notes: [],
  };
};

const state = {
  activeTimeClock: null,
  //timeClock: {},
  timeClock: getDefaultTimeClock(),
  timeClocks: [],
  //timeClockDuration: getTimeClockDuration(),
  timeClockDuration: null,
  timeClockDurationInterval: null,
};

const getters = {
  activeTimeClock: (state) => {
    return state.activeTimeClock;
  },
  timeClock: (state) => {
    return state.timeClock;
  },
  timeClocks: (state) => {
    return state.timeClocks;
  },
  timeClockDuration: (state) => {
    return state.timeClockDuration;
  },
};

const actions = {
  resetTimeClockState({ commit }) {
    commit("resetTimeClockState");
  },
  async deleteActiveTimeClock({ commit, state }) {
    let timeClock = state.activeTimeClock;
    if (!timeClock) {
      return;
    }
    commit("setActiveTimeClock", null);
    commit("setTimeClockDuration", null);
    await idb.deleteTimeClock(timeClock);
  },
  removeActiveTimeClock({ commit }) {
    return new Promise((resolve) => {
      commit("setActiveTimeClock", null);
      commit("setTimeClockDuration", null);
      clearInterval(state.timeClockDurationInterval); //redundant
      resolve();
    });
  },
  removeTimeClocks({ commit }) {
    commit("timeClocks", null);
  },
  setTimeClockDuration({ commit }, clockStart) {
    commit("setTimeClockDuration", clockStart);
  },
  removeTimeClockDuration({ commit }) {
    commit("removeTimeClockDuration");
  },
  async getTimeClock(_, timeClockId) {
    let dbTimeClock;
    if (!Vue.prototype.$online) {
      dbTimeClock = await idb.getTimeClock(timeClockId);
      return dbTimeClock;
      //commit("timeClock", dbTimeClock);
    } else {
      return await TimeClockService.getTimeClock(timeClockId).then((r) => {
        let timeClock = r.data;
        //creating a single object with notes due to idb structure... todo.
        return TimeClockService.getTimeClockNotes({
          TimeclockId: timeClockId,
        }).then((r) => {
          timeClock.notes = r.data;
          return timeClock;
        });
      });
    }
  },
  async getTimeClockLatest(_, params) {
    return await TimeClockService.getTimeClockLatest(params).then((r) => {
      return r.data;
    });
  },

  async getTimeClocks({ commit, state, dispatch }, params) {
    dispatch("loader/done", null, { root: true });
    commit("timeClocks", []);
    try {
      let timeClocks;
      if (!Vue.prototype.$online) {
        timeClocks = await idb.getTimeClocks();
      } else {
        dispatch("loader/pending", null, {
          root: true,
        });
        setTimeout(
          () => {
            dispatch("loader/done", null, { root: true });
          },
          Vue.prototype.$online ? 10000 : 1000,
        );
        if (!params) {
          params = {
            "paging.orderbyfield": "Id",
            "paging.ascending": "false",
            "paging.skipnum": 0,
            "paging.takenum": 25,
          };
        }
        const r = await TimeClockService.getTimeClocks(params);
        timeClocks = r.data;
        idb.deleteOldTimeClocks();
        idb.deleteClockIns();

        //check server
        const c = await TimeClockService.getTimeClockLatest();
        const clockServer = c.data;
        if (clockServer.clockEnd) {
          await dispatch("removeActiveTimeClock");
        } else {
          if (!state.activeTimeClock || clockServer.clockStart !== state.activeTimeClock.clockStart) {
            await dispatch("removeActiveTimeClock");
            commit("setActiveTimeClock", clockServer);
            commit("setTimeClockDuration", clockServer.clockStart);
          }
        }
      }

      if (!timeClocks) {
        dispatch("loader/done", null, {
          root: true,
        });
        return;
      }

      let count = 0;
      timeClocks.sort((a, b) => (a.clockStart < b.clockStart ? 1 : b.clockStart < a.clockStart ? -1 : 0));
      commit("timeClocks", timeClocks);

      timeClocks.forEach((c) => {
        if (!Vue.prototype.$online && !c.clockEnd && !state.activeTimeClock) {
          commit("setActiveTimeClock", c);
          commit("setTimeClockDuration", c.clockStart);
        }
        if (Vue.prototype.$online) {
          if (count < 100) {
            idb.saveTimeClock(c).catch((err) => {
              console.error(err);
            });
          }
          if (!c.clockEnd) {
            let clockIn = {
              jobId: c.jobId,
              costCodeId: c.costCodeId,
              timeCategoryId: c.timeCategoryId,
              clockStart: c.clockStart,
              clockStartReasonId: c.clockStartReasonId,
              clockStartLatLng: c.clockStartLatLng,
              clockStartLatLngMethod: Helpers.methods.getDeviceInfo(),
              timeclockNote: TimeClockService.getTimeClockNotes({
                TimeclockId: c.id,
              }).data?.note, // c.timeclockNote, //doesn't exist on timeclock
              fitForDuty: c.latestFitForDuty,
              incidentReport: c.latestIncident,
              timeClockId: c.id,
            };
            idb.saveClockIn(clockIn).catch((err) => {
              console.error(err);
            });
          }
        }
        count++;
      });

      dispatch("loader/done", null, {
        root: true,
      });
      return timeClocks;
    } catch (e) {
      dispatch("loader/done", null, {
        root: true,
      });
    }
  },

  async appendTimeClocks({ commit, dispatch }, params) {
    dispatch("loader/pending", null, {
      root: true,
    });
    setTimeout(() => {
      this.$store.dispatch("loader/done", null, { root: true });
    }, 10000);
    try {
      let timeClocks, response;
      await TimeClockService.getTimeClocks(params).then((r) => {
        timeClocks = r.data;
        response = r;
      });
      if (!timeClocks) {
        dispatch("loader/done", null, {
          root: true,
        });
        return;
      }
      timeClocks.sort((a, b) => (a.clockStart < b.clockStart ? 1 : b.clockStart < a.clockStart ? -1 : 0)); //recent to later
      timeClocks.forEach((c) => {
        commit("addTimeClocks", c);
        idb.saveTimeClock(c).catch((err) => {
          console.error(err);
        });
      });
      dispatch("loader/done", null, {
        root: true,
      });
      return response;
    } catch (e) {
      dispatch("loader/done", null, {
        root: true,
      });
    }
  },

  async doClockIn({ commit }, clockIn) {
    //save local clockins...if user goes offline after clocking in, and purge them on api clockouts
    //if offline save local timeclocks... for now, local timeclocks are replaced when they are pulled from api
    if (!Vue.prototype.$online) {
      clockIn.clockStartNote = clockIn.timeClockNote;
      idb.saveClockIn(clockIn).catch((err) => {
        console.error(err);
      });

      let timeClock = {
        jobId: clockIn.jobId,
        jobName: clockIn.jobName,
        costCodeId: clockIn.costCodeId,
        costCodeCode: clockIn.costCodeCode,
        timeCategoryId: clockIn.timeCategoryId,
        timeCategoryName: clockIn.timeCategoryName,
        status: "p",
        clockStart: clockIn.clockStart,
        clockStartReasonId: clockIn.clockStartReasonId,
        clockStartLatLng: clockIn.clockStartLatLng,
        clockStartLatLngMethod: clockIn.clockStartLatLngMethod,
        clockEnd: null,
        clockEndReasonId: null,
        clockEndLatLng: clockIn.clockEndLatLng,
        clockEndLatLngMethod: clockIn.clockEndLatLngMethod,
        latestFitForDuty: clockIn.fitForDuty,
        latestIncident: clockIn.incidentReport,
        createdOn: DateTime.utc().toISO(),
        createdById: clockIn.userId,
        syncedOn: null,
        timeClockNote: clockIn.timeClockNote,
        clockStartNote: clockIn.timeClockNote, //not included on api endpoint props
        clockEndNote: "", //not included on api endpoint props
      };
      await idb
        .saveTimeClock(timeClock)
        .then((timeClock) => {
          commit("setActiveTimeClock", timeClock);
        })
        .catch((err) => {
          console.error(err);
        });
      return timeClock;
    }
    //clean api call:
    let params = {};
    params.jobId = clockIn.jobId;
    params.costCodeId = clockIn.costCodeId;
    params.timeCategoryId = clockIn.timeCategoryId;
    params.clockStart = clockIn.clockStart;
    params.clockStartReasonId = clockIn.clockStartReasonId;
    params.clockStartLatLng = clockIn.clockStartLatLng;
    params.clockStartLatLngMethod = clockIn.clockStartLatLngMethod;
    params.timeclockNote = clockIn.timeClockNote;
    params.fitForDuty = clockIn.fitForDuty;
    params.incidentReport = clockIn.incidentReport;
    return TimeClockService.clockIn(params).then((r) => {
      let timeClockId = r.data?.timeclockId;
      clockIn.timeClockId = timeClockId; ///for clockout check on sync
      if (clockIn.timeClockId) {
        (clockIn.clockStartNote = clockIn.timeClockNote),
          idb.saveClockIn(clockIn).catch((err) => {
            console.error(err);
          });
      }

      return TimeClockService.getTimeClock(timeClockId).then((r) => {
        let timeClock = r.data;
        commit("setActiveTimeClock", timeClock);
        idb.saveTimeClock(timeClock).catch((err) => {
          console.log(err + " - we have an idb issue");
        });
        return timeClock;
      });
    });
  },

  //clockin offline... go online... activetimeclock does not get set with the existing clockin... a refresh will set it.
  async doClockOut({ dispatch }, clockOut) {
    if (!Vue.prototype.$online) {
      await idb
        .getActiveClockIn()
        .then((result) => {
          let activeClockIn = result;
          //Object.assign(activeClockIn, clockOut);
          activeClockIn.clockEnd = clockOut.clockEnd;
          activeClockIn.clockEndLatLng = clockOut.clockEndLatLng;
          activeClockIn.clockEndLatLngMethod = clockOut.clockEndLatLngMethod;
          activeClockIn.clockEndReasonId = clockOut.clockEndReasonId;
          activeClockIn.fitForDuty = null;
          activeClockIn.incidentReport = clockOut.incidentReport;
          activeClockIn.clockEndNote = clockOut.timeClockNote;

          idb.saveClockIn(activeClockIn).catch((err) => {
            console.error(err);
          });
        })
        .catch((err) => {
          console.error(err);
        });
      let timeClock;
      await idb
        .getNewestTimeClock()
        .then((result) => {
          timeClock = result;
          //timeClock = { ...clockOut, ...result };
          timeClock.latestIncident = clockOut.incidentReport;
          timeClock.clockEnd = clockOut.clockEnd;
          timeClock.clockEndLatLng = clockOut.clockEndLatLng;
          timeClock.clockEndLatLngMethod = clockOut.clockEndLatLngMethod;
          timeClock.clockEndReasonId = clockOut.clockEndReasonId;
          timeClock.latestIncident = clockOut.incidentReport;
          timeClock.clockEndNote = clockOut.timeClockNote;
          //update idb clockin object
          idb.saveTimeClock(timeClock).catch((err) => {
            console.error(err);
          });
        })
        .catch((err) => {
          console.error(err);
        });
      await dispatch("removeActiveTimeClock");
      return timeClock;
    } else {
      return await TimeClockService.clockOut(clockOut).then(() => {
        let timeClock = TimeClockService.getTimeClock(clockOut.id);
        idb.deleteClockIns().catch((err) => {
          console.error(err);
        });
        dispatch("removeActiveTimeClock");
        return timeClock;
      });
    }
  },
  saveTimeClock(_, timeClock) {
    if (!Vue.prototype.$online) {
      return "No Connection";
    }
    return TimeClockService.saveTimeClock(timeClock);
  },
  async deleteTimeClock(_, timeClockId) {
    await idb.deleteTimeClock(timeClockId).catch((err) => {
      console.error(err);
    });
    let params = {};
    params.id = timeClockId;
    return TimeClockService.deleteTimeClock(params);
  },
  saveTimeClockNote(_, note) {
    if (!Vue.prototype.$online) {
      return "No Connection";
    }
    return TimeClockService.saveTimeClockNote(note);
  },
  editTimeClockNote(_, note) {
    if (!Vue.prototype.$online) {
      return "No Connection";
    }
    return TimeClockService.editTimeClockNote(note);
  },
  async getJobs(_, params) {
    if (!Vue.prototype.$online) {
      return await idb.getClockInJobs();
    }
    return TimeClockService.getJobs(params).then((r) => {
      let clockInJobs = r.data;
      if (clockInJobs) {
        let dbJobs = clockInJobs;
        dbJobs.id = 1; //one set per company
        idb.saveClockInJobs(dbJobs).catch((err) => {
          console.log(err);
        });
      }
      return clockInJobs;
    });
  },
  async getUserJobs(_, params) {
    if (!Vue.prototype.$online) {
      return await idb.getClockInJobs();
    }
    return TimeClockService.getUserJobs(params).then((r) => {
      let clockInJobs = r.data;
      if (clockInJobs) {
        let dbJobs = clockInJobs;
        dbJobs.id = 1; //one set per company
        idb.saveClockInJobs(dbJobs).catch((err) => {
          console.log(err);
        });
      }
      return clockInJobs;
    });
  },
  async getGroupJobs(_, params) {
    if (!Vue.prototype.$online) {
      return await idb.getClockInJobs();
    }
    return TimeClockService.getGroupJobs(params).then(async (r) => {
      let groupJobs = r.data;
      if (groupJobs) {
        // Get existing jobs
        let existingJobs = (await idb.getClockInJobs()) || [];
        // Combine while filtering out duplicates based on jobId
        let dbJobs = [...existingJobs, ...groupJobs].filter(
          (job, index, self) => index === self.findIndex((j) => j.jobId === job.jobId),
        );
        dbJobs.id = 1;
        idb.saveClockInJobs(dbJobs).catch((err) => {
          console.log(err);
        });
      }
      return groupJobs;
    });
  },
  async getJobCostCodes(_, params) {
    if (!Vue.prototype.$online) {
      if (params?.jobId) {
        return await idb.getJobCostCodes(params.jobId);
      }
    } else {
      return TimeClockService.getJobCostCodes(params).then((r) => {
        if (!params?.jobId) return;
        let jobCostCodes = r.data;
        if (jobCostCodes) {
          let dbCostCodes = jobCostCodes;
          dbCostCodes.id = params.jobId;
          idb.saveJobCostCodes(dbCostCodes).catch((err) => {
            console.error(err);
          });
        }
        return jobCostCodes;
      });
    }
  },
  async getCategories(_, params) {
    if (!Vue.prototype.$online) {
      return await idb.getClockInCategories();
    }
    return TimeClockService.getCategories(params).then((r) => {
      let clockInCategories = r.data;
      if (clockInCategories) {
        let dbCategories = clockInCategories;
        dbCategories.id = 1; //one set per company
        idb.saveClockInCategories(dbCategories).catch((err) => {
          console.error(err);
        });
      }
      return clockInCategories;
    });
  },
  async getCostCodes(_, params) {
    if (!Vue.prototype.$online) {
      return await idb.getClockInCostCodes();
    }
    return TimeClockService.getCostCodes(params).then((r) => {
      let clockInCostCodes = r.data;
      return clockInCostCodes;
    });
  },
  async getReasons(_, params) {
    if (!Vue.prototype.$online) {
      return await idb.getClockInOutReasons().catch((err) => {
        console.error(err);
      });
    }
    return TimeClockService.getReasons(params).then((r) => {
      let clockInOutReasons = r.data;
      if (clockInOutReasons) {
        let dbReasons = clockInOutReasons;
        dbReasons.id = 1; //one set per company
        idb.saveClockInOutReasons(dbReasons).catch((err) => {
          console.error(err);
        });
      }
      return clockInOutReasons;
    });
  },
};

const mutations = {
  resetTimeClockState(state) {
    Object.assign(state.timeClock, getDefaultTimeClock());
  },
  timeClock(state, value) {
    state.timeClock = value;
  },
  timeClocks(state, value) {
    state.timeClocks = value;
  },
  addTimeClocks(state, value) {
    state.timeClocks.push(value);
  },
  setActiveTimeClock(state, value) {
    state.activeTimeClock = value;
  },
  removeTimeClockDuration(state) {
    state.timeClockDuration = null;
  },
  setTimeClockDuration(state, value) {
    if (!value) {
      clearInterval(state.timeClockDurationInterval);
      state.timeClockDurationInterval = null;
      state.timeClockDuration = null;
      state.timeClockDurationPending = false;
    } else {
      state.timeClockDurationInterval = null;
      state.timeClockDurationInterval = setInterval(() => {
        const date1 = DateTime.utc();
        const date2 = DateTime.fromISO(value);
        state.timeClockDuration = date1.diff(date2).toFormat("h:mm:ss");
      }, 1000);
      state.timeClockDurationPending = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
