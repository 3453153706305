<template>
  <div :style="statusBarStyle">
    <router-view />
  </div>
</template>

<script>
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import update from "@/mixins/update";
import sync from "@/mixins/sync";
import { mapGetters, mapActions } from "vuex";
import { TokenService } from "@services";
import { Trans } from "@/plugins/Translation";
import tracer from "@/diagnostics/tracer.js";

tracer.init();

export default {
  name: "App",

  metaInfo() {
    let robots;
    if (process.env.VUE_APP_META_ROBOTS) {
      robots = process.env.VUE_APP_META_ROBOTS;
    } else {
      robots = "index,follow";
    }
    return {
      titleTemplate: "%s | Acrisure Safety",
      meta: [{ name: "robots", content: robots }],
    };
  },

  mixins: [update, sync],

  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("user", ["user"]),
    isAndroid() {
      return Capacitor.getPlatform() === "android";
    },
    statusBarStyle() {
      // Only add padding on Android
      if (this.isAndroid) {
        return {
          paddingTop: "24px", // Default Android status bar height
        };
      }
      return {};
    },
  },

  data() {
    return {
      pushes: null,
      pushReceived: null,
      info: "",
      statusBarHeight: 0,
    };
  },

  methods: {
    ...mapActions("user", ["setUser"]),
    ...mapActions("auth", ["loadAccessToken"]),
    ...mapActions("notifications", ["getPushNotifications"]),
    ...mapActions("settings", ["setLang"]),
    ...mapActions("ui", ["setShowPlanners"]),

    async initializeStatusBar() {
      if (Capacitor.isPluginAvailable("StatusBar")) {
        try {
          if (this.isAndroid) {
            // Make status bar translucent on Android
            await StatusBar.setOverlaysWebView({ overlay: true });
            // Set background color to match app theme
            await StatusBar.setBackgroundColor({ color: this.settings.darkTheme ? "#000000" : "#FFFFFF" });
          }

          // Set style based on theme
          await StatusBar.setStyle({
            style: this.settings.darkTheme ? Style.Dark : Style.Light,
          });
        } catch (error) {
          console.error("Error setting status bar:", error);
        }
      }
    },

    async checkNavVisibility() {
      try {
        if (this.user.navigationAccess?.planners === undefined || this.user.navigationAccess?.planners === null) {
          const env = this.$store.state.api.apiURL.includes("dev")
            ? "dev"
            : this.$store.state.api.apiURL.includes("stage")
            ? "stage"
            : "prod";

          const timestamp = Date.now();
          const response = await fetch(
            `https://clockworksafety-public.s3.us-west-2.amazonaws.com/${env}/companyplanners.json?t=${timestamp}`,
          );
          const data = await response.json();
          const company = data.companies.find((c) => c.id == this.user?.companyId);
          if (company) {
            this.setShowPlanners(company.planner);
          }
        } else {
          this.setShowPlanners(this.user.navigationAccess.planners);
        }
      } catch (error) {
        return;
      }
    },
  },

  async created() {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      this.getPushNotifications();
    }
    if (this.settings.lang) {
      Trans.changeLanguage(this.settings.lang);
    }
    await this.initializeStatusBar();
  },

  mounted() {
    if (this.settings && this.settings.darkTheme) {
      this.$vuetify.theme.dark = true;
    } else if (this.settings.darkTheme === false) {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }

    if (TokenService.getToken()) {
      this.loadAccessToken();
      this.setUser();
    }

    if (this.$platform == "mobile") {
      this.appStateListener = Capacitor.App.addListener("appStateChange", ({ isActive }) => {
        if (isActive) {
          this.checkNavVisibility();
        }
      });
    } else {
      this.visibilityHandler = () => {
        if (document.visibilityState === "visible") {
          this.checkNavVisibility();
        }
      };
      document.addEventListener("visibilitychange", this.visibilityHandler);
    }
  },

  watch: {
    user: {
      handler(newUser) {
        if (newUser && Object.keys(newUser).length > 0) {
          this.checkNavVisibility();
        }
      },
      immediate: true,
    },
    "settings.darkTheme": {
      handler() {
        this.initializeStatusBar();
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.$platform == "mobile") {
      this.appStateListener?.remove();
    } else {
      document.removeEventListener("visibilitychange", this.visibilityHandler);
    }
  },
};
</script>
