const state = {
  drawer: false,
  showPlanners: false,
};

const getters = {
  navDrawerState: (state) => {
    return state.drawer;
  },
  showPlanners: (state) => {
    return state.showPlanners;
  },
};

const mutations = {
  navDrawer: (state, command) => {
    command === "open" ? (state.drawer = true) : (state.drawer = false);
  },
  setShowPlanners: (state, value) => {
    state.showPlanners = value;
  },
};

const actions = {
  navDrawer: ({ commit }, command) => {
    commit("navDrawer", command);
  },
  setShowPlanners: ({ commit }, value) => {
    commit("setShowPlanners", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
